<template>
    <div class="containerFeedback-list">
        <app-list :opt="opt" @get="onGet"></app-list>
        <el-dialog title="现场图片" :visible.sync="dialogVisible" width="50%" :close-on-click-modal="false">
            <img class="containerFeedback-list__img" :src="imgSrc" alt="图片" />
            <el-pagination small layout="prev, pager, next" :total="imgCount" :page-size="1"
                @current-change="currentChange"></el-pagination>
        </el-dialog>
        <el-dialog title="短信提醒" :visible.sync="messageDialogVisible" :close-on-click-modal="false">
      <div class="SMS-reminder">
        <el-form ref="messageForm" :model="messageForm" :rules="rules" label-width="140px">
          <el-form-item label="提醒人姓名" prop="name">
            <label slot="label"><span style="color:red">*</span>&nbsp;&nbsp;提醒人姓名</label>
            <el-input placeholder="请输入提醒人姓名" v-model="messageForm.name" clearable>
            </el-input>
          </el-form-item>
          <el-form-item label="提醒人手机号码" prop="phone">
            <label slot="label"><span style="color:red">*</span>&nbsp;&nbsp;提醒人手机号码</label>
            <el-input placeholder="请输入提醒人手机号码" maxlength="11" v-model="messageForm.phone" clearable>
            </el-input>
          </el-form-item>
          <el-form-item label="短信模板" prop="describe" class="textarea">
            <el-input type="textarea" maxlength="300" disabled placeholder="请输入安装位置" v-model="messageForm.describe"
              clearable>
            </el-input>
          </el-form-item>
        </el-form>
      </div>
      <div class="dialog-footer">
        <el-button type="primary" style="width:140px" @click="submit()">提交</el-button>
      </div>
    </el-dialog>
    </div>
</template>
<script>
    let validMobile = (rule, value, callback) => {
        if (value == "" || value == undefined) {
            callback();
        } else {
            let reg = /^1[3456789]\d{9}$/;
            if (!reg.test(value)) {
                callback(new Error("手机号格式不对"));
            } else {
                callback();
            }
        }
    };
    let validName = (rule, value, callback) => {
        if (value == "" || value == undefined) {
            callback();
        } else {
            //姓名支持输入中英文字母，汉字，1~16个字符
            let reg = /^[a-zA-Z\u4e00-\u9fa5]{1,16}$/;
            if (!reg.test(value)) {
                callback(new Error("姓名格式不对"));
            } else {
                callback();
            }
        }
    };
    let validMessageName = (rule, value, callback) => {
    if (value == "" || value == undefined) {
      callback("请输入提醒人姓名");
    } else {
      //姓名支持输入中英文字母，汉字，1~12个字符
      let reg = /^[a-zA-Z\u4e00-\u9fa5]{2,12}$/;
      if (!reg.test(value)) {
        callback(new Error("提醒人姓名格式不对"));
      } else {
        callback();
      }
    }
  };
  let validMessagePhone = (rule, value, callback) => {
    if (value == "" || value == undefined) {
      callback("请输入提醒人手机号码");
    } else {
      let reg = /^1[3456789]\d{9}$/;
      if (!reg.test(value)) {
        callback(new Error("提醒人手机号码格式不对"));
      } else {
        callback();
      }
    }
  };
    export default {
        data() {
            let _this = this;
            return {
                messageForm: {
                   name: '',
                   phone: '',
                   describe: '模板：【威富】云数新零售平台收到一条意见反馈，用户姓名：郑丫丫，手机号码：13455667788，请及时处理。'
                },
                rules: {
                   name: [{
                     validator: validMessageName,
                     trigger: ['blur']
                  }],
                  phone: [{
                     validator: validMessagePhone,
                     trigger: ['blur']
                  }],
                },
                messageDialogVisible: false,
                imgSrc: "",
                imgList: [],
                imgCount: 2,
                dialogVisible: false,
                opt: {
                    title: "意见反馈",
                    search: [{
                            key: "name",
                            label: "姓名",
                            maxlength: 16,
                            rules: [{
                                validator: validName,
                                trigger: ['blur']
                            }]
                        },
                        {
                            key: "phone",
                            label: "手机号",
                            rules: [{
                                validator: validMobile,
                                trigger: ['blur']
                            }]
                        },
                        {
                            key: "state", 
                            label: "状态",
                            type: "select-no-filterable",
                            opt: {
                                list: [{
                                        label: "未处理",
                                        value: "0"
                                    },
                                    {
                                        label: "已处理",
                                        value: "1"
                                    }
                                ]
                            }
                        }
                    ],
                    columns: [{
                            label: "姓名",
                            key: "name"
                        },
                        {
                            label: "手机号",
                            key: "phone"
                        },

                        {
                            label: "反馈描述",
                            key: "description",
                            width: "400"
                        },
                        {
                            label: "现场图片",
                            key: "picList",
                            type: "action-but-img",
                            opt: {
                                list: [{
                                    label: "查看",
                                    on(row) {
                                        _this.imgSrc = row.picList[0];
                                        _this.imgList = row.picList;
                                        _this.imgCount = row.picList.length;
                                        _this.dialogVisible = true;
                                    }
                                }]
                            }
                        },
                        {
                            label: "是否允许三天内联系",
                            key: "isAllowContactStr"
                        },
                        {
                            label: "提交时间",
                            key: "createTime"
                        },
                        {
                            label: "状态",
                            key: "stateStr",
                            type: "action-alt",
                            opt: {
                                activeText: "已处理",
                                inactiveText: "未处理",
                                on(row, item) {
                                    _this.$confirm('标记该状态已处理', '提示', {
                                        confirmButtonText: '确定',
                                        cancelButtonText: '取消',
                                        type: 'warning'
                                    }).then(() => {
                                        let dto = {
                                            id: row.id,
                                            state: 1
                                        }
                                        _this.post("mall-service/feedback/update", dto, {
                                            isUseResponse: true,
                                            isNotShowError: true
                                        }).then(res => {
                                            if (res.data.code == 0) {
                                                row[item.key] = true;
                                                _this.$message({
                                                    showClose: true,
                                                    message: "修改成功!",
                                                    type: "success"
                                                });
                                            } else {
                                                _this.$message({
                                                    showClose: true,
                                                    message: "修改失败!",
                                                    type: "error"
                                                });
                                            }
                                        }).catch(res => {
                                            _this.$message({
                                                showClose: true,
                                                message: "修改失败!",
                                                type: "error"
                                            });
                                        });
                                    });
                                }
                            }
                        }
                    ],
                    buttons: [{
                        type: 0,
                        icon: 'question',
                        name: '短信提醒',
                        on() {
                            if (_this.$refs["messageForm"]) {
                                _this.$refs["messageForm"].resetFields(); //初始化表单
                            }
                            var dto = {
                                configKey: 'mall_sms'
                            }
                            _this.post("/landlord-service/sysConfig/selectByKey",dto,{
                                isUseResponse: true
                            }).then(res => {
                                let arr = res.data.data.split(',');  
                                _this.messageForm.phone = arr[0]
                                _this.messageForm.name = arr[1]
                                _this.messageDialogVisible = true;
                            })
                        }
                    }]
                }
            };
        },
        created() {
            console.log("containerFeedback-list created!");
        },
        deactivated() {
            this.dialogVisible = false;
        },
        methods: {
            onGet(opt) {
                var dto = {
                    pageNum: opt.skip,
                    pageSize: opt.limit,
                    name: opt.searchForm.name,
                    phone: opt.searchForm.phone,
                    state: opt.searchForm.state
                };

                this.post("mall-service/feedback/page/query", dto, {
                    isUseResponse: true
                }).then(res => {
                    if (res.data.code == 0) {
                        res.data.data = res.data.list;
                        res.data.data.forEach(item => {
                            item.stateStr = [false, true][item.state]; //0待处理  1已处理
                            item.isAllowContactStr = ["不允许", "允许"][item.isAllowContact];
                        });
  
                    }
                    opt.cb(res.data);
                });
            },
            currentChange(idx) {
                this.imgSrc = this.imgList[idx - 1];
            },
            submit() {
                let _this = this;
                this.$refs['messageForm'].validate((valid) => {
                if (valid) {
                    var dto = {
                         configKey: 'mall_sms',
                         configValue:  this.messageForm.phone + ',' +  this.messageForm.name,
                    };
                 this.post('/landlord-service/sysConfig/save-or-update',dto).then(res => {
                   _this.$message({
                    showClose: true,
                    message: '提交成功！',
                    type: 'success',
                })
                    _this.messageDialogVisible = false;
                });
          }
        })
      }
        }
    };
</script>
<style lang="scss" scoped>
    .containerFeedback-list__img {
        width: 100%;
    }

    .SMS-reminder {
    width: 90%;
    display: inline-block;
    margin: auto;

    .editTitle {
      display: inline-block;
      padding-right: 10px;
      text-align: right;
      width: 101px;
      color: #606266
    }

    .textarea {
      // width: 310px;
      height: 80px;
      border-color: #dcdfe6;
      color: #303133;

      textarea {
        width: 100%;
        height: 100%;
        resize: none;
      }
    }

  }
  .dialog-footer {
    width: 100%;
    text-align: center;
  }
</style>